<template>
  <div>
    <BarraCrud
      :onNovo="onNovo"
      :botaoNovo="canManage"
      :onFiltrar="filtrar"
    />
    <div>
      <!-- <b-tabs
        content-class="mt-3"
        fill
        style="font-size: 11px"
      >
        <b-tab
          title="Todos"
          @click="grupoSelecionado = null"
        ></b-tab>
        <b-tab
          v-for="grupo in grupos"
          :key="`grupo_${grupo.cod_grupo}`"
          :title="grupo.Grupo"
          @click="grupoSelecionado = grupo.cod_grupo"
        ></b-tab>

        <template
          #tabs-end
          v-if="canManage"
        >
          <b-nav-item
            role="presentation"
            @click.prevent="novoGrupo"
            href="#"
            ><i class="fas fa-plus"></i> <b>Novo</b>
          </b-nav-item>
        </template>
      </b-tabs> -->
      <div class="d-flex justify-content-between">
        <div
          class="text-left my-2 py-2 mx-2 px-2"
          style="max-width: 300px"
        >
          <b>Grupo de Produtos</b>
          <b-select
            size="xl"
            :options="
              grupos.map((it) => ({ value: it.cod_grupo, text: it.Grupo }))
            "
            v-model="grupoSelecionado"
          >
          </b-select>
        </div>
        <div
          v-if="loadingTable"
          class="text-right"
          style="place-content: flex-end"
        >
          <b-spinner
            class="mr-1"
            label="Spinning"
            type="grow"
            small
          ></b-spinner>
          Carregando...
        </div>
      </div>
    </div>
    <b-skeleton-table v-if="loadingTable"> </b-skeleton-table>
    <ProdutosTable
      @salvar="onSalvar"
      :filtro="filtro"
      :produtos="produtos"
      @editar="editar($event)"
      @duplicar="duplicar($event)"
      :loading="loadingTable"
      :grupoSelecionado="grupoSelecionado"
      :canManage="canManage"
      :pagination="pagination"
      v-if="!loadingTable"
    />

    <b-modal
      id="modal-produtos"
      content-class="prodModal"
      no-fade
    >
      <template #modal-title>
        <i class="fas fa-pizza-slice"></i>
        {{
          typeof produto_edit.cod_produto === "number"
            ? `Editando ${produto_edit.nome}`
            : "Adicionando Produto"
        }}
      </template>

      <template #modal-footer="{ cancel }">
        <b-btn
          variant="primary"
          class="mr-3"
          @click="$refs.produtoForm.salvar()"
          type="submit"
          ><i class="fas fa-save"> </i> Salvar</b-btn
        >
        <b-btn
          variant="danger"
          @click="cancel()"
          ><i class="fas fa-ban"> </i> Cancelar</b-btn
        >
      </template>
      <ProdutosForm
        @salvou="carregar()"
        ref="produtoForm"
        :produto_edit="produto_edit"
        :grupoInicial="grupoSelecionado"
      />
    </b-modal>

    <b-modal
      id="modal-grupos"
      size="lg"
    >
      <GruposDeProdutos
        :botaoAdicionar="true"
        @adicionou="carregarGrupos"
        nomeCustomizado="Grupo de Produtos"
        iconeCustomizado="fas fa-boxes"
        :breadcrumb="false"
        contentClass="none"
        @salvou="carregarGrupos"
      />
    </b-modal>
  </div>
</template>

<script>
import BarraCrud from "@/components/common/BarraCrud";
import ProdutosTable from "@/components/Produtos/ProdutosTable.vue";
import ProdutosForm from "@/components/Produtos/ProdutosForm.vue";
import ProdutoLib from "@/libs/ProdutoLib.js";
import ProdutoGrupoLib from "@/libs/ProdutoGrupoLib.js";
import GruposDeProdutos from "@/views/Configuracoes/Cadastros/GruposDeProdutos.vue";

export default {
  components: {
    BarraCrud,
    ProdutosTable,
    ProdutosForm,
    GruposDeProdutos,
  },
  data() {
    return {
      filtro: "",
      produtos: [],
      grupos: [],
      produto_edit: {},
      loadingTable: false,
      grupoSelecionado: null,
      canManage: true,
      pagination: {
        limit: 10,
        page: 1,
        total: 0,
        totalPages: 0,
        filters: null,
      },
    };
  },
  watch: {
    "pagination.page": {
      handler() {
        if (this.loadingTable === false) {
          console.log("call from watch", this.loadingTable);
          this.carregar();
        }
      },
      deep: true,
    },
    grupoSelecionado() {
      if (this.grupoSelecionado && this.grupoSelecionado.cod_grupo === null) {
        this.grupoSelecionado = null;
      }
      this.pagination.page = 1;
      this.carregar();
    },
  },
  methods: {
    duplicar(produto) {
      this.$swal({
        title: "Deseja duplicar o produto?",
        text: "Essa ação não poderá ser desfeita",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (result) => {
        if (result.isConfirmed) {
          produto.valor_extra = this.formatFloatToReal(produto.valor_extra);
          produto.preco_venda = this.formatFloatToReal(produto.preco_venda);
          produto.preco_custo = this.formatFloatToReal(produto.preco_custo);
          if (!produto.ingredientes) produto.ingredientes = [];
          await this.$forceUpdate();
          try {
            let novo = { ...produto };
            delete novo.cod_produto;
            // novo.nome = `Cópia de ${novo.nome}`;
            novo.nome = `${novo.nome}`;
            novo = await ProdutoLib.store(novo);
            console.log(novo)
            if (novo.status === 201 || novo.status === 200) {
              this.onSalvar(novo);
              this.showToast("success", "Produto duplicado com sucesso!");
              // this.showToast("success", "Produto salvo com sucesso!");
              this.carregar();
              this.$store.dispatch("auth/atualizarCardapio");
            }
          } catch (error) {
            this.showToast(
              "error",
              "Ops! Alguma coisa deu errado :/\n" + error
            );
          }
        }
      });
    },
    cancel() {
      this.$bvModal.hide("modal-produtos");
    },
    filtrar(filtro) {
      this.filtro = filtro;
      this.pagination.page = 1;
      this.carregar();
    },
    limpar() {
      for (const key in this.produto_edit) {
        this.produto_edit[key] = null;
      }
    },
    novoGrupo() {
      this.$bvModal.show("modal-grupos");
    },
    onNovo() {
      this.limpar();
      this.$bvModal.show("modal-produtos");
    },
    showToast(
      icone = "",
      message = "",
      time = 1500,
      description = "",
      confirmButton = false,
      position = "top-end"
    ) {
      this.$swal.fire({
        title: message,
        icon: icone,
        toast: true,
        position: position,
        timer: time,
        showConfirmButton: confirmButton,
        timerProgressBar: true,
        text: description,

        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });
    },
    editar(produto) {
      Object.assign(this.produto_edit, produto);
      this.produto_edit.valor_extra = this.formatFloatToReal(
        this.produto_edit.valor_extra
      );
      // this.produto_edit.preco_venda = this.formatFloatToReal(
      //   this.produto_edit.preco_venda
      // );
      // this.produto_edit.preco_custo = this.formatFloatToReal(
      //   this.produto_edit.preco_custo
      // );
      this.$forceUpdate();
      this.$bvModal.show("modal-produtos");
    },
    async carregar() {
      if (this.loadingTable) return;
      this.$bvModal.hide("modal-produtos");
      try {
        this.loadingTable = true;
        delete this.pagination.data;
        this.pagination.filters = {
          ...this.pagination.filters,
          grupo: this.grupoSelecionado,
          filtro: this.filtro,
        };
        // let page = this.pagination.page;
        // delete this.pagination.page;
        this.pagination = await ProdutoLib.get("", this.pagination);
        this.produtos = this.pagination.data;
        // this.pagina.page = page;
        console.log("page", this.pagination);
        // this.produtos = this.$store.state.cardapio.reduce((ret, vl) => {
        //   ret.push(...vl.produtos);
        //   return ret;
        // }, []).sort((a,b)=>a.cod_produto-b.cod_produto);
        console.log("produtos", this.produtos);
        await this.$nextTick();
        this.loadingTable = false;
      } catch (error) {
        this.showToast("error", "Ops! Alguma coisa deu errado :/\n" + error);
      }
    },
    onSalvar(produto) {
      console.log("produto", produto);
      this.$store.dispatch("auth/atualizarProduto", produto);
    },
    async carregarGrupos() {
      try {
        this.loadingTable = true;
        this.grupos = [
          { cod_grupo: null, Grupo: "TODOS" },
          ...(await ProdutoGrupoLib.get()).sort((a, b) => {
            return a.Grupo > b.Grupo ? -1 : 1;
          }),
        ];
        this.loadingTable = false;

        await this.$nextTick();
        await this.carregar();
        this.$bvModal.hide("modal-grupos");
        this.loadingTable = false;
      } catch (error) {
        this.showToast("error", error.message);
      }
    },
  },
  mounted() {
    this.carregarGrupos();
    // console.log("user", this.user);
    this.canManage = this.user.nivel < 2;
  },
};
</script>

<style></style>
