import axios from "@/utils/axios";

const get = async (cod_produto="", pagination=null, justStatus=false) => {
  let result = [];
  await axios
    .get("produtos/get",  {params:{cod_produto, pagination,justStatus}})
    .then((data) => {
      result = data.data;
    })
    .catch((error) => {
      throw new Error(error.response.data.error.message);
    });

  return result;
};

const store = async (data) => {
  let result;

  await axios
    .post("produtos/store", data)
    .then((res) => (result = res))
    .catch((error) => { throw new Error(error.response.data.error) });

  return result;
};

const update = async (cod_produto,data) => {
  let result;

  await axios
    .put(`produtos/update/${cod_produto}`, data)
    .then((res) => (result = res))
    .catch((error) => { throw new Error(error.response.data.error) });

  return result;
};

const del = async (cod_produto) => {
  let result;

  await axios
    .delete(`produtos/delete/${cod_produto}`)
    .then((res) => (result = res))
    .catch((error) => { throw new Error(error.response.data.error) });

  return result;
};

const verificarDisponibilidade = async (cod_produto, cod_empresa) => {
  try {
    return (await axios.post("/produtos/verificarDisponibilidade", { cod_produto, cod_empresa })).data;
  }
  catch (err) {
    console.log("ERROR ON GET DISPONIBILIDADE", err);
    return null;
  }
}
export default {
  get,
  store,
  update,
  del,
  verificarDisponibilidade
};
