<template>
  <div>
    <div

      class="row justify-content-between button-tipo-preco"
      @click="$emit('click')"
    >
      {{ title }}

      <b-icon :icon="opened ? 'chevron-down' : 'chevron-up'"></b-icon>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    opened: {
      type: Boolean,
      default: false,
    },
  },
  
};
</script>

<style scoped lang="scss">
.button-tipo-preco {
  cursor: pointer;
  &:hover{
       color: #212529;
    background-color: #f8f9fa ;
  }
}
</style>
