<template>
  <div>
    <b-form @submit.prevent.stop="$emit('salvar')">
      <b-form-group>
        <div class="row">
          <div class="col-12 col-sm-2">
            <label>Código</label>

            <b-form-input
              size="sm"
              type="number"
              v-model="$v.produto.cod_produto.$model"
              :state="validateState('cod_produto')"
              disabled
            />
            <b-form-invalid-feedback>
              {{
                !produtoExistente
                  ? "O código do produto é obrigatório!"
                  : "Há outro produto com este código! "
              }}<a
                size="sm"
                style="cursor: pointer"
                v-if="produtoExistente"
                @click="carregarProduto()"
              >
                Clique aqui para carregar o produto</a
              >
            </b-form-invalid-feedback>
          </div>
          <div class="col-12 col-sm-5">
            <label>Nome</label>
            <b-form-input
              size="sm"
              ref="inputNome"
              v-model="$v.produto.nome.$model"
              :state="validateState('nome')"
            />
            <b-form-invalid-feedback>
              O Nome do produto é obrigatório!
            </b-form-invalid-feedback>
          </div>

          <div class="col-12 col-md-5">
            <!-- {{produto.tabela_preco}} -->
            <label>Grupo</label>

            <v-select
              ref="vueSelect"
              v-model="$v.produto.cod_grupo.$model"
              :reduce="(grupos) => grupos.code"
              :options="grupos"
              @search:blur="carregaTabelaPrecos"
              autocomplete="on"
            >
              <template #search="{ attributes, events }">
                <input
                  :class="[
                    'vs__search',
                    typeof validateState('cod_grupo') == 'boolean' &&
                    !validateState('cod_grupo')
                      ? 'isInvalid'
                      : '',
                  ]"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>

              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  Nenhum resultado para <em>{{ search }}</em
                  >.
                </template>
                <em
                  style="opacity: 0.5"
                  v-else
                  >Digite para procurar por um grupo.</em
                >
              </template>
            </v-select>
            <b-form-invalid-feedback
              :force-show="
                typeof validateState('cod_grupo') == 'boolean' &&
                !validateState('cod_grupo')
              "
            >
              O Grupo é obrigatório!
            </b-form-invalid-feedback>
          </div>
        </div>
      </b-form-group>

      <div class="row">
        <div class="col-12 col-sm-7">
          <b-form-radio-group
            :options="precoOptions"
            v-model="produto.tipo_preco"
            class="mb-3"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
          />

          <div v-if="produto.tipo_preco === 'Tabela'">
            <h5>Tabela De Preços</h5>
            <TabelaDePrecos
              :exibe-atualizar-todos-produtos="false"
              :tabela-precos="produto.precos"
              :tabela_preco_empresa="produto.tabela_preco_empresa"
              @valueChangesEmpresa="
                (e) => {
                  this.produto.tabela_preco_empresa = e;
                  this.$forceUpdate();
                }
              "
            />
          </div>

          <div v-if="produto.tipo_preco === 'Unico'">
            <b-form-group>
              <label>Valor Venda</label>
              <b-form-input
                v-money="moneyMask"
                v-model="$v.produto.preco_venda.$model"
                :state="validateState('preco_venda')"
              />
            </b-form-group>

            <b-form-group>
              <label>Valor Custo</label>
              <b-form-input
                v-money="moneyMask"
                v-model="$v.produto.preco_custo.$model"
                :state="validateState('preco_custo')"
              />
            </b-form-group>
          </div>
          <hr />
          <b-tabs class="mt-5">
            <div class="p-3">
              <b-tab title="Ingredientes">
                <IngredientesListagem
                  hideTitle
                  :isPrecoUnico="produto.tipo_preco === 'Unico'"
                  :ingredientes-prop="$v.produto.ingredientes.$model"
                  @ingredientesChanged="onIngredienteChange($event)"
                  :state="validateState('ingredientes')"
                />
              </b-tab>
              <b-tab title="Personalizar Estoque">
                <small class="text-muted"> * Clique para personalizar </small>
                <PersonalizarEstoque
                  ref="PersonalizarEstoqueComp"
                  :ingredientes="produto.ingredientes"
                  :tabela-de-precos="produto.precos"
                />
              </b-tab>
            </div>
          </b-tabs>
        </div>
        <div class="col-12 col-sm-5 mt-sm-0 mt-3">
          <h5>Opções</h5>

          <b-form-group>
            <div class="row">
              <div class="col-12">
                <b-form-checkbox
                  v-model="produto.aceita_ingrediente"
                  :value="2"
                  :unchecked-value="1"
                  ref="teste"
                >
                  Aceita Ingredientes
                </b-form-checkbox>
              </div>
              <div class="col-12">
                <b-form-checkbox
                  v-model="produto.aceita_extra"
                  :value="2"
                  :unchecked-value="1"
                >
                  Aceita Extras
                </b-form-checkbox>
              </div>
              <div class="col-12">
                <b-form-checkbox
                  v-model="produto.imprime_mesa"
                  :value="2"
                  :unchecked-value="1"
                >
                  Imprime Salão
                </b-form-checkbox>
              </div>
            </div>
          </b-form-group>
          <hr />

          <b-form-group>
            <div class="row">
              <div class="col-12 col-md-6">
                <label class="text-nowrap mr-3">Limite Ingredientes</label>
                <b-form-input
                  class=""
                  size="sm"
                  type="number"
                  v-model="$v.produto.ingredientes_quantidade.$model"
                  :state="validateState('ingredientes_quantidade')"
                />
              </div>
              <div class="col-12 col-md-6">
                <label class="text-nowrap mr-3">Valor do Extra</label>
                <b-form-input
                  class=""
                  size="sm"
                  v-money="moneyMask"
                  v-model="$v.produto.valor_extra.$model"
                  :state="validateState('valor_extra')"
                />
              </div>
            </div>
          </b-form-group>
          <hr />

          <b-form-group>
            <label
              >Tipo Vendas
              <b-icon
                v-if="
                  typeof validateState('tipo_venda') == 'boolean' &&
                  !validateState('tipo_venda')
                "
                icon="exclamation-circle"
                variant="danger"
            /></label>
            <div class="row">
              <div class="col">
                <b-form-checkbox
                  v-model="$v.produto.tipo_venda.$model"
                  value="BALCAO"
                >
                  Balcão
                </b-form-checkbox>
              </div>
              <div class="col">
                <b-form-checkbox
                  v-model="$v.produto.tipo_venda.$model"
                  value="ENTREGA"
                >
                  Entrega
                </b-form-checkbox>
              </div>
              <div class="col">
                <b-form-checkbox
                  v-model="$v.produto.tipo_venda.$model"
                  value="BUSCAR"
                >
                  Buscar
                </b-form-checkbox>
              </div>
              <div class="col">
                <b-form-checkbox
                  v-model="$v.produto.tipo_venda.$model"
                  value="MESA"
                >
                  Mesa
                </b-form-checkbox>
              </div>
            </div>
            <b-form-invalid-feedback
              :force-show="
                typeof validateState('tipo_venda') == 'boolean' &&
                !validateState('tipo_venda')
              "
            >
              É necessário ao menos um tipo de venda!
            </b-form-invalid-feedback>
          </b-form-group>
          <hr />

          <b-form-group>
            <div class="row">
              <div class="col">
                <b-form-checkbox
                  class="mt-3"
                  v-model="produto.controla_estoque"
                  value="1"
                  unchecked-value="0"
                >
                  Controlar Estoque
                </b-form-checkbox>
              </div>
              <div class="col">
                <label class="m-0">Estoque Mínimo (alerta)</label>
                <b-form-input
                  v-model="produto.estoque_minimo"
                  type="number"
                />
              </div>
            </div>
          </b-form-group>

          <hr />

          <StatusPorEmpresa :status="produto.status" />
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12 col-sm-7"></div>

        <div class="col-12 col-sm-5">
          <!-- {{ produto.status }} -->
        </div>

        <div class="col-12">
          <FiscalForm
            :obj="produto.info_fiscal"
            @update="(ev) => (produto.info_fiscal = ev)"
          />
        </div>
        <div class="col-12 col-md-6 mt-2">
          <hr />
          <b-card>
            <b-card-title><b-icon-images /> Fotos / Imagens</b-card-title>
            <file-upload
              v-model="produto.imagens"
              :toBucket="false"
              :setDefault="true"
              :enableTitle="false"
            />
          </b-card>
        </div>
        <div class="col-12 col-md-6 mt-2">
          <hr />

          <b-card>
            <b-card-title
              ><i class="fa fa-people-carry" /> Fornecedor</b-card-title
            >
            <b-form-group>
              <label>Nome</label>
              <select-fornecedor
                :fornecedorId="produto.fornecedor_id"
                @change="produto.fornecedor_id = $event"
              />
            </b-form-group>
          </b-card>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import TabelaDePrecos from "@/components/common/TabelaDePrecos.vue";
import IngredientesListagem from "@/components/Ingredientes/IngredientesListagem.vue";
import PersonalizarEstoque from "@/components/Ingredientes/PersonalizarEstoque";
import ProdutoGrupoLib from "@/libs/ProdutoGrupoLib";
import ProdutoLib from "@/libs/ProdutoLib.js";
import StatusPorEmpresa from "@/components/common/StatusPorEmpresa";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import FiscalForm from "./FiscalForm.vue";
import FileUpload from "../common/FileUpload.vue";
import SelectFornecedor from "../Fornecedores/SelectFornecedor.vue";

export default {
  mixins: [validationMixin],
  components: {
    TabelaDePrecos,
    IngredientesListagem,
    PersonalizarEstoque,
    StatusPorEmpresa,
    FiscalForm,
    FileUpload,
    SelectFornecedor,
  },
  props: {
    produto_edit: Object,
    grupoInicial: Number,
  },
  validations() {
    {
      return {
        produto: {
          cod_produto: {
            async validaCod() {
              return await this.verificaCod(this.produto.cod_produto);
            },
          },
          cod_grupo: {
            required,
          },
          nome: {
            required,
          },
          ingredientes_quantidade: {
            // required: () => {
            //   if (this.produto.tipo_preco == "Tabela") {
            //     return (
            //       typeof this.produto.ingredientes_quantidade != "undefined" &&
            //       this.produto.ingredientes_quantidade.toString() != ""
            //     );
            //   }
            //   return true;
            // },
          },
          preco_custo: {
            required: () => {
              if (this.produto.tipo_preco == "Unico") {
                return (
                  typeof this.produto.preco_custo != "undefined" &&
                  this.produto.preco_custo.toString() != ""
                );
              }
              return true;
            },
          },
          preco_venda: {
            required: () => {
              if (this.produto.tipo_preco == "Unico") {
                return (
                  typeof this.produto.preco_venda != "undefined" &&
                  this.produto.preco_venda.toString() != ""
                );
              }
              return true;
            },
          },
          valor_extra: {
            required,
          },
          tipo_venda: {
            minLength() {
              return (
                this.produto.tipo_venda && this.produto.tipo_venda.length > 0
              );
            },
          },
          // tabela_preco: {
          //   minLength() {
          //     return this.produto.tabela_preco.length > 0
          //   },
          // },
          ingredientes: {
            // minLength() {
            //   this.$forceUpdate();
            //   if (this.produto.tipo_preco == "Tabela") {
            //     return (
            //       this.produto.ingredientes.filter((ingrediente) => {
            //         if (ingrediente._rowStatus) {
            //           if (ingrediente._rowStatus != "deleted") {
            //             return true;
            //           }
            //         } else {
            //           return true;
            //         }
            //       }).length > 0
            //     );
            //   }
            //   return true;
            // },
          },
        },
      };
    }
  },
  data() {
    return {
      produto: {
        cod_produto: null,
        cod_grupo: null,
        nome: "",

        descricao: "",
        aceita_extra: 0,
        aceita_ingrediente: 0,
        imprime_mesa: 0,
        ingredientes_quantidade: 0,
        valor_extra: 0.0,
        preco_venda: 0.0,
        preco_custo: 0.0,
        tipo_venda: ["BALCAO", "MESA", "ENTREGA", "BUSCAR"],
        ordem_exibicao: 0,
        precos: [],
        ingredientes: [],
        tipo_preco: "Unico",
        fornecedor_id: null,
        status: this.$store.state.empresa_ativa
          ? [
              {
                cod_empresa: this.$store.state.empresa_ativa.cod_empresa,
                empresa: this.$store.state.empresa_ativa.nome,
                status: 1,
                _rowStatus: "added",
              },
            ]
          : [],
        controla_estoque: 0,
        estoque_minimo: 0,
        info_fiscal: {
          NCM: "",
          GTIN: "",
          ImpostoFederal: 0,
          ImpostoEstadual: 0,
        },
        //tabela_preco_empresa:[],
      },
      precoOptions: [
        { item: "Tabela", name: "Tabela de Preços" },
        { item: "Unico", name: "Preço Único" },
      ],

      editando: false,
      produtoExistente: null,
      grupos: [],
      cod_grupo_atual: null,
    };
  },
  methods: {
    async carProd() {
      if (this.produto && this.produto.cod_produto) {
        let produto = await ProdutoLib.get(this.produto.cod_produto);
        if (produto && produto.length > 0) {
          this.produto = produto[0];
          if (
            this.produto_edit.info_fiscal &&
            typeof this.produto_edit.info_fiscal == "string"
          ) {
            try {
              this.produto_edit.info_fiscal = JSON.parse(
                this.produto_edit.info_fiscal
              );
            } catch (error) {
              console.log("error on convert fiscal", error);
            }
          }
          if (this.produto.preco_venda.toString().indexOf("R$") < 0) {
            console.log(this.produto.preco_venda);
            this.produto.preco_venda = this.formatFloatToReal(
              this.produto.preco_venda
            );
            console.log(123);
          }
          console.log("prod", this.produto);
          // this.produto_edit = produto[0];
          this.$forceUpdate();
          this.editando = true;
          if (!this.produto.tabela_preco_empresa) {
            this.produto.tabela_preco_empresa = [];
            console.log("reseted");
          }
        }
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.produto[name];

      return $dirty ? !$error : null;
    },
    async carregarGrupos() {
      // this.$forceUpdate();
      console.log("start carregarGrupos");
      this.grupos = (await ProdutoGrupoLib.get()).map((el) => {
        return { label: el.Grupo, code: el.cod_grupo, ...el };
      });
      console.log("gps", this.produto_edit, this.grupoInicial);
      if (
        (!this.produto_edit || !this.produto_edit.cod_produto) &&
        this.grupoInicial
      ) {
        let grupo = this.grupos.find(
          (grupo) => grupo.cod_grupo == this.grupoInicial
        );
        this.produto.cod_grupo = grupo.cod_grupo;
        this.produto.info_fiscal = grupo.fiscal;
      }
      console.log("done carregarGrupos");
    },

    async verificaCod(cod_produto) {
      if (this.editando && cod_produto == this.produto_edit.cod_produto) {
        return true;
      }

      if (cod_produto != null && cod_produto.toString() != "") {
        let produto = await ProdutoLib.get(cod_produto);
        // console.log('produto', produto)
        if (produto.length > 0) {
          if (this.produtoExistente) {
            if (cod_produto != this.produtoExistente.cod_produto) {
              this.produtoExistente = produto[0];
              return false;
            } else {
              return true;
            }
          } else {
            this.produtoExistente = produto[0];
            return false;
          }
        } else {
          this.produtoExistente = null;
        }

        return true;
      }
      this.produtoExistente = null;
      return this.editando ? false : true;
    },
    onIngredienteChange({ newValue, item }) {
      console.log(item);
      this.$forceUpdate();
      if (newValue) {
        this.produto.ingredientes = newValue;
      }
      if (item) {
        if (item._rowStatus === "deleted") {
          this.$refs.PersonalizarEstoqueComp.removeIngrediente(
            item?.cod_ingrediente
          );
        } else if (item._rowStatus === "added") {
          this.$refs.PersonalizarEstoqueComp.setNewIngrediente(item);
        }
      }
    },
    async carregaTabelaPrecos() {
      const grupo = this.grupos.find(
        (grupo) => grupo.cod_grupo == this.produto.cod_grupo
      );
      if (
        grupo &&
        this.produto.cod_grupo.toString() != "" &&
        this.cod_grupo_atual != this.produto.cod_grupo
      ) {
        this.produto.info_fiscal = grupo.fiscal;
      }
      console.log("grupo", grupo);
      if (grupo) {
        const tabela = grupo["tabela_preco"];
        // console.log(tabela);
        if (
          this.produto.cod_grupo.toString() != "" &&
          this.cod_grupo_atual != this.produto.cod_grupo &&
          tabela &&
          tabela.length > 0
        ) {
          this.$swal
            .fire({
              title: "Carregar tabela de preços do grupo?",
              icon: "question",
              showCancelButton: true,
              confirmButtonText: "Sim",
              cancelButtonText: "Não",
            })
            .then(async (result) => {
              if (result.isConfirmed) {
                this.cod_grupo_atual = this.produto.cod_grupo;
                this.produto.precos = tabela;
              }
            });
          // this.produto.info_fiscal =
        }
      }
      this.$forceUpdate();

      await this.$nextTick();
    },
    async carregarProduto() {
      this.$swal
        .fire({
          title: "Você tem certeza?",
          text: "Ao fazer isso as informações atuais serão sobrepostas!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim!",
          cancelButtonText: "Não.",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$forceUpdate();
            this.carregaProdutoRaw();
            this.$forceUpdate();
          }
        });
    },
    async carregaProdutoRaw() {
      Object.assign(this.produto, this.produtoExistente);
      this.produto_edit.cod_produto = this.produtoExistente.cod_produto;
      this.editando = true;
      if (!this.produto.tabela_preco_empresa) {
        this.produto.tabela_preco_empresa = [];
        console.log("reseted");
      }
    },
    async salvar() {
      console.log(this.produto);
      // if(this.produto){
      //   return;
      // }
      this.$v.produto.$touch();
      if (this.$v.produto.$anyError) {
        return;
      }

      if (this.produto.cod_produto == "") {
        await this.$swal
          .fire({
            title: "Atenção! O produto está sem código.",
            text: `Ao gerar um produto sem código, será gerado um código automaticamente pra ele, deseja continuar mesmo assim?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim. Continuar!",
            cancelButtonText: "Não.",
          })
          .then(async (result) => {
            if (!result.isConfirmed) {
              return;
            }
          });
      }
      this.produto.valor_extra = this.formatValorReal(this.produto.valor_extra);
      this.produto.preco_venda = this.formatValorReal(this.produto.preco_venda);
      this.produto.preco_custo = this.formatValorReal(this.produto.preco_custo);
      console.log(
        "prod",
        this.editando,
        this.produto.cod_produto,
        this.produto_edit.cod_produto
      );

      if (this.editando) {
        if (
          this.produto.cod_produto != null &&
          this.produto_edit.cod_produto &&
          this.produto.cod_produto != this.produto_edit.cod_produto
        ) {
          this.$swal
            .fire({
              title: "Atenção! O código do Produto foi alterado.",
              text: `O código do produto foi alterado de ${this.produto_edit.cod_produto} para ${this.produto.cod_produto}, tem certeza que deseja continuar?`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Sim. Continuar!",
              cancelButtonText: "Não.",
            })
            .then(async (result) => {
              if (result.isConfirmed) {
                this.loading = true;
                try {
                  const result = await ProdutoLib.update(
                    this.produto_edit.cod_produto,
                    this.produto
                  );
                  // console.log(result);
                  if (result.status === 200) {
                    this.$emit("salvou");
                    await this.$store.dispatch("auth/atualizarCardapio");
                    this.showToast("success", "Produto salvo com sucesso!");
                  } else {
                    this.showToast("error", "Erro ao salvar o produto!");
                  }
                } catch (error) {
                  this.showToast("error", error.message);
                }
                this.loading = false;
              }
            });
        } else {
          try {
            const result = await ProdutoLib.update(
              this.produto.cod_produto,
              this.produto
            );
            if (result.status === 201 || result.status === 200) {
              this.$emit("salvou");
              this.showToast("success", "Produto salvo com sucesso!");
            }
          } catch (error) {
            this.showToast("error", error.message);
          }
        }
      } else {
        try {
          const result = await ProdutoLib.store(this.produto);
          if (result.status === 201 || result.status === 200) {
            this.$emit("salvou");
            await this.$store.dispatch("auth/atualizarCardapio");
            this.showToast("success", "Produto salvo com sucesso!");
          }
          // console.log(result);
        } catch (error) {
          this.showToast("error", error.message);
        }
      }
    },
  },
  created() {
    this.carregarGrupos();
  },
  mounted() {
    console.log("produto", this.produto_edit);
    if (this.produto_edit) {
      if (
        this.produto_edit.info_fiscal &&
        typeof this.produto_edit.info_fiscal == "string"
      ) {
        try {
          this.produto_edit.info_fiscal = JSON.parse(
            this.produto_edit.info_fiscal
          );
        } catch (error) {
          console.log("error on convert fiscal", error);
        }
      }
      if (typeof this.produto_edit.cod_produto == "number") {
        this.produto = JSON.parse(JSON.stringify(this.produto_edit));
        if (this.produto.preco_venda.toString().indexOf("R$") > -1) {
          console.log(this.produto.preco_venda);
          // this.produto.preco_venda = this.formatToFloat(this.produto.preco_venda);
          console.log(123);
        }
        console.log("prod", this.produto, this.produto_edit);
        this.editando = true;
        if (!this.produto.tabela_preco_empresa) {
          this.produto.tabela_preco_empresa = [];
          console.log("reseted");
        }
        this.carProd();

        this.$forceUpdate();
      }
    }
  },
  watch: {
    "produto.tipo_preco"() {
      this.$nextTick(() => {
        this.$v.$reset();
        this.produto.precos = [
          {
            cod_tipo: 8,
            nome: "UNICO",
            meia: this.produto.preco_venda,
            inteira: this.produto.preco_venda,
          },
        ];
      });
    },
    "produto.precos"() {},
  },
};
</script>
