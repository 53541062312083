<template>
  <div>
    <b-table
      outlined
      bordered
      class="shadow-sm"
      small
      :fields="campos"
      :items="ingredientes"
      show-empty
      style="font-size: 11px"
    >
      <template #cell(qtd_meia)="{ item }">
        <div>
          <money
            class="form-control text-center"
            v-bind="getMask(item.tipo_unidade || 'kg')"
            @input="hasChanged(item)"
            v-model="item.qtd_meia"
          />
        </div>
      </template>
      <template #cell(qtd_inteira)="{ item }">
        <div>
          <money
            @input="hasChanged(item)"
            class="form-control text-center"
            v-bind="getMask(item.tipo_unidade || 'kg')"
            v-model="item.qtd_inteira"
          />
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  props: {
    ingredientes: {
      type: Array,
      default: [],
    },
    tipo: Object,
  },
  data() {
    return {
      campos: [
        { key: "ingrediente", label: "Ingrediente" },
        { key: "qtd_meia", label: "Meia (KG)" },
        { key: "qtd_inteira", label: "Inteira (KG)" },
        // { key: "tipo_unidade", label: "tp)" },
      ],

      moneyMask: {
        decimal: ",",
        thousands: ".",
        prefix: "kg ",

        precision: 3,
        masked: false,
      },
    };
  },
  methods: {
    hasChanged(value) {
      //   console.log('hasChaged', value);
      this.$emit("input", value);
    },
    getMask(tp) {
      return {
        decimal: ",",
        thousands: ".",
        prefix: tp + " ",

        precision: 3,
        masked: false,
      };
    },
  },
};
</script>

<style></style>
