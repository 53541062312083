<template>
  <div>
    <b-table
      :items="produtosFiltrados"
      :fields="camposComEmpresas"
      striped
      stacked="sm"
      responsive="sm"
      :busy="loading"
      small
      style="font-size: 11px"
    >
      <!-- <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template> -->

      <template v-for="campo in camposComEmpresas" v-slot:[`cell(${campo.key})`]="row">
        <div :key="campo.key">
          <div v-if="campo.key.includes('empresa')">
            <b-form-checkbox
              switch
              :unchecked-value="0"
              :checked="
                row.item.status && campo.index >=0 && row.item.status[campo.index]
                  ? row.item.status[campo.index].status
                  : 0
              "
              :value="1"
              @change="onChangeStatus(row.item, campo)"
              :disabled="loadingInterno"
            />
          </div>
          <div v-else>
            {{ row.item[campo.key] }}
          </div>
        </div>
      </template>

      <template #cell(actions)="row">
        <div v-if="canManage">
          <b-btn variant="warning" size="sm" class="mr-3" @click="editar(row.item)">
            <i class="fas fa-edit" /> Editar
          </b-btn>
          <b-btn variant="secondary" size="sm" class="mr-3" @click="duplicar(row.item)">
            <i class="fas fa-copy" /> Duplicar
          </b-btn>
          <b-btn variant="danger" size="sm" @click="excluir(row.item)">
            <i class="fas fa-trash" /> Excluir
          </b-btn>
        </div>
      </template>

      <template #cell(preco_venda)="row">
        <b-input
          v-model="row.item.preco_venda"
          @blur="updateProduct(row.item)"
          style="margin: 0 auto; max-width: 100px; text-align: center; background: white"
          v-money="moneyMask"
        />
      </template>
      <template #cell(nome)="row">
        <b-input v-model="row.item.nome" @blur="updateProduct(row.item)" />
      </template>
    </b-table>
    <b-pagination
      v-model="pagination.page"
      :total-rows="pagination.total"
      :per-page="pagination.limit"
    />
  </div>
</template>

<script>
import ProdutoLib from "@/libs/ProdutoLib.js";

export default {
  props: {
    produtos: { Array, required: true },
    filtro: String,
    loading: Boolean,
    grupoSelecionado: { type: Number, required: false },
    canManage: {
      type: Boolean,
      default: true,
    },
    pagination: Object,
  },
  data() {
    return {
      empresas: [],
      loadingInterno: false,
      paginacao: {
        registros_por_pagina: 30,
        pagina_atual: 1,
      },
    };
  },
  methods: {
    async updateProduct(product) {
      let produto = JSON.parse(JSON.stringify(product));
      produto.valor_extra = this.formatValorReal(produto.valor_extra);
      produto.preco_venda = this.formatValorReal(produto.preco_venda);
      produto.preco_custo = this.formatValorReal(produto.preco_custo);
      await ProdutoLib.update(produto.cod_produto, produto);
      this.showToast("success", "Produto salvo com sucesso!");
      this.$store.dispatch("auth/atualizarProduto", produto);
    },
    duplicar(produto) {
      this.$emit("duplicar", produto);
    },
    editar(produto) {
      this.$emit("editar", produto);
    },
    onRowSelected(produto) {
      if (produto) {
        this.$emit("editar", produto[0]);
      }
      this.$refs.produtosTable.clearSelected();
    },
    async carregaEmpesas() {
      this.$forceUpdate();
      await this.$nextTick();
      this.empresas = [...this.$store.state.empresas.empresas];
      this.empresas.push(this.$store.state.empresas);
      this.empresas = this.empresas.sort((a, b) =>
        a.cod_empresa > b.cod_empresa ? 1 : a.cod_empresa < b.cod_empresa ? -1 : 0
      );
      if (this.user && this.user.tipo && this.user.tipo == "Gerente") {
        if (
          this.user.configuracoes &&
          this.user.configuracoes.empresas &&
          this.user.configuracoes.empresas.length > 0
        ) {
          this.empresas = this.empresas.filter((x) => {
            return (
              this.user.configuracoes.empresas.filter((y) => y == x.cod_empresa).length >
              0
            );
          });
        } else {
          this.empresas = this.empresas.filter(
            (x) => x.cod_empresa == this.user.cod_empresa
          );
        }
      }
    },
    async onChangeStatus(produto, campo) {
      produto = JSON.parse(JSON.stringify(produto))
      console.log("produto changed", produto, 'campo', campo)
      if (!produto.status[campo.index]) {
        produto.status.push({
          cod_empresa: campo.cod_empresa,
          empresa: campo.empresa,
          status: 1,
          _rowStatus: "added",
        });
      } else {
        produto.status[campo.index].status = produto.status[campo.index].status ? 0 : 1;

        if (!produto.status[campo.index]._rowStatus) {
          // this.$set(produto.status[campo.index], "_rowStatus", "updated");
        }
      }
 
      await this.updateProduct(produto)
      // await this.salvar(produto);
    },
    async salvar(produto) {
      try {
        this.loadingInterno = true;
        await this.updateProduct(produto)

        this.$emit("salvar", produto);
        // await this.$store.dispatch("auth/atualizarCardapio");
      } catch (error) {
        this.showToast("error", `Ops, alguma coisa deu errado :/\n${error.message}`);
      } finally {
        this.loadingInterno = false;
      }
    },
    async excluir(produto) {
      this.$forceUpdate();
      await this.$nextTick();
      const { cod_produto, cod_grupo } = produto;
      if (cod_produto) {
        this.$swal
          .fire({
            title: "Você tem creteza que deseja excluir este produto?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim. Excluir!",
            cancelButtonText: "Não.",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              const result = await ProdutoLib.del(cod_produto);
              // console.log( result)
              if (result.data && result.status == 200) {
                this.$store.dispatch("auth/apagarProduto", {
                  cod_produto,
                  cod_grupo,
                });
                const i = this.produtos.findIndex((p) => p.cod_produto == cod_produto);
                if (i != -1) this.produtos.splice(i, 1);
                this.$swal.fire({
                  title: "Produto excluído com sucesso!",
                  icon: "success",
                  toast: true,
                  timer: 1500,
                  position: "top-end",
                  showConfirmButton: false,
                });
              } else {
                this.$swal.fire({
                  title: "Erro ao excluir produto!",
                  icon: "error",
                  toast: true,
                  timer: 1500,
                  position: "top-end",
                  showConfirmButton: false,
                });
              }
            }
          });
      }
    },
  },
  async mounted() {
    this.carregaEmpesas();
  },
  computed: {
    produtosFiltrados() {
      return this.produtos
        .map((prod) => ({
          ...prod,
          preco_venda: this.formatFloatToReal(prod.preco_venda),
        }))
        .filter((prod) => {
          if (this.grupoSelecionado) {
            return prod.cod_grupo == this.grupoSelecionado;
          }
          return true;
        });
    },
    camposComEmpresas() {
      return [
        { key: "cod_produto", label: "Código", sortable: true },
        { key: "nome", label: "Nome", class: "text-left" },
        { key: "preco_venda", label: "Preço", class: "text-center" },
        ...this.empresas
          .filter((x) => this.user.nivel < 2 || x.cod_empresa == this.user.cod_empresa)
          .map((e, i) => {
            return {
              key: `empresa_${e.cod_empresa}`,
              label: e.nome,
              cod_empresa: e.cod_empresa,
              empresa: e.nome,
              index: i,
              class: "text-center empresasTable",
            };
          }),
        { key: "actions", label: "" },
      ];
    },
  },
};
</script>

<style lang="scss">
.empresasTable {
  white-space: nowrap;
  font-size: 0.7rem;
}
</style>
