<template>
  <div>
    <div v-if="tipos.length > 0">
      <div
        v-for="tipo of tipos"
        :key="`tipo_${tipo.cod_tipo_preco}_${tipo.tipo}`"
      >
        <div
          class="row p-2 rounded"
          :class="{ 'bg-light mb-2': tipo.isOpened }"
        >
          <div class="col border-bottom mb-3">
            <button-tipo-preco
              :title="tipo.tipo"
              @click="tipo.isOpened = !tipo.isOpened"
              :opened="tipo.isOpened"
              >{{ tipo }}</button-tipo-preco
            >
            <div class="row">
              <div class="col p-0 pr-2 mt-2">
                <b-collapse v-model="tipo.isOpened">
                  <TablePersonalizacao
                    @input="onChangeIngrediente"
                    :ingredientes="tipo.ingredientes"
                    :tipo="tipo"
                  />
                  <b-btn
                    size="sm"
                    @click="clearFields(tipo.ingredientes, tipo)"
                    class="d-block ml-auto mb-2"
                  >
                    <i class="fas fa-trash"></i> Limpar
                  </b-btn>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TablePersonalizacao from "./TablePersonalizacao.vue";
import ButtonTipoPreco from "./ButtonTipoPreco.vue";
export default {
  components: {
    TablePersonalizacao,
    ButtonTipoPreco,
  },
  props: {
    ingredientes: Array,
    tabelaDePrecos: Array,
  },
  data() {
    return {
      tipos: [],
      loadingTipos: false,
    };
  },
  methods: {
    fetchTipos() {
      this.$forceUpdate();

      try {
        console.log(
          "this.tabelaDePrecos=>",
          this.tabelaDePrecos,
          this.ingredientes
        );
        this.$nextTick(() => {
          this.loadingTipos = true;
          this.tipos = this.tabelaDePrecos
            ? this.tabelaDePrecos.map((tipo) => ({
                cod_tipo: tipo.cod_tipo,
                tipo: tipo.tipo,
                isOpened: false,
                ingredientes: this.ingredientes
                  ? this.ingredientes.map((ingred) => ({
                      ingrediente: ingred.ingrediente,
                      cod_ingrediente: ingred.cod_ingrediente,
                      tipo_unidade: ingred.tipo_unidade,
                      ...ingred.personalizarEstoque.find(
                        (x) => x.cod_tipo_preco === tipo.cod_tipo
                      ),
                    }))
                  : [],
              }))
            : [];
          //console.log("tipos", this.tipos)
          if (this.tipos && this.tipos.length > 0) {
            this.tipos[0].isOpened = true;
          }
        });
      } catch (error) {
        console.debug("Error on fetch tipos: ", error);
      } finally {
        this.loadingTipos = false;
      }
    },
    clearFields(ingredientes = [], tipo) {
      console.log(ingredientes);
      if (ingredientes && Array.isArray(ingredientes)) {
        if (ingredientes.length > 0) {
          this.$swal
            .fire({
              title: `Limpar campos`,

              html: `Você tem certeza que deseja limpar os campos do grupo ${tipo.tipo}? <br> <small>Isso irá limpar todos valores de pesagem dos ingredientes do grupo.</small>`,
              icon: "question",
              showCancelButton: true,
              confirmButtonText: "Sim. Limpar!",
              cancelBUttonText: "Não",
            })
            .then((a) => {
              if (a.isConfirmed) {
                for (const ingrediente of ingredientes) {
                  if (typeof ingrediente === "object") {
                    ingrediente.qtd_inteira = 0;
                    ingrediente.qtd_meia = 0;
                  }
                }
              }
            });
        }
      }
    },
    setNewIngrediente(ingrediente) {
      // console.log("ingrediente added", ingrediente);
      if (ingrediente) {
        for (const tipo of this.tipos) {
          tipo.ingredientes.push({
            ingrediente: ingrediente.ingrediente,
            cod_ingrediente: ingrediente.cod_ingrediente,
            tipo_unidade: ingrediente.tipo_unidade,
            ...ingrediente.personalizarEstoque.find(
              (e) => e.cod_tipo_preco === tipo.cod_tipo
            ),
          });
        }
      }
      this.$forceUpdate();
    },
    removeIngrediente(id) {
      if (id) {
        for (const tipo of this.tipos) {
          const idxIngrediente = tipo.ingredientes.findIndex(
            (i) => i.cod_ingrediente === id
          );
          console.log("idx", idxIngrediente);
          if (idxIngrediente > -1) {
            tipo.ingredientes.splice(idxIngrediente, 1);
          }
        }
      }
      this.$forceUpdate();
    },

    onChangeIngrediente(value) {
      // console.log('valor recebido',value, this.ingredientes);
      const idxIngrediente = this.ingredientes.findIndex(
        (i) => i.cod_ingrediente === value.cod_ingrediente
      );
      // console.log("achou o ingrediente: ", idxIngrediente);
      if (idxIngrediente > -1) {
        let idxEstoque = this.ingredientes[
          idxIngrediente
        ].personalizarEstoque.findIndex(
          (e) =>
            e.cod_ingrediente === value.cod_ingrediente &&
            e.cod_tipo_preco === value.cod_tipo_preco
        );
        //  console.log('idxEstoque',idxEstoque);
        if (idxEstoque > -1) {
          // this.ingredientes[idxIngrediente].personalizarEstoque[
          //   idxEstoque
          // ] = value;
          const mudouValor =
            this.ingredientes[idxIngrediente].personalizarEstoque[idxEstoque]
              .qtd_meia !== value.qtd_meia ||
            this.ingredientes[idxIngrediente].personalizarEstoque[idxEstoque]
              .qtd_inteira !== value.qtd_inteira;

          if (mudouValor) {
            this.$set(
              this.ingredientes[idxIngrediente].personalizarEstoque[idxEstoque],
              "qtd_meia",
              value.qtd_meia
            );
            this.$set(
              this.ingredientes[idxIngrediente].personalizarEstoque[idxEstoque],
              "qtd_inteira",
              value.qtd_inteira
            );
            if (
              this.ingredientes[idxIngrediente].personalizarEstoque[idxEstoque]
                .cod_estoque
            ) {
              this.ingredientes[idxIngrediente].personalizarEstoque[
                idxEstoque
              ]._rowStatus = "updated";
            }
          }
        }
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.fetchTipos();
    }, 300);
  },
  watch: {},
  computed: {},
};
</script>

<style scoped></style>
